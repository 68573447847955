export const navLinks = [
  {
    id: 1,
    label: "Homepage",
    route: "/",
  },
  {
    id: 2,
    label: "About Us",
    route: "/about-us",
  },
  {
    id: 3,
    label: "Our Solutions",
    route: "/our-solutions",
  },
  {
    id: 4,
    label: "Contact Us",
    route: "/contact-us",
  },
];
