import React from 'react'
import clx from '../utils/clx'

export default function Text({as, classname,onclick, understroke, children, noGradient}) {
      const H1classes = clx(
        "text-[30px] lg:text-[48px] font-bold font-semibold font-montserrat relative ",
        onclick && 'cursor-pointer select-none',
            classname
      )
      const H2classes = clx(
            "text-[45px] font-bold font-semibold font-montserrat",
            onclick && 'cursor-pointer select-none',
            classname
      )
      const H3classes = clx(
            "text-[42px] font-bold font-semibold font-montserrat",
            onclick && 'cursor-pointer select-none',
            classname
      )
      const H4classes = clx(
        "text-[20px] lg:text-[32px] font-bold font-semibold font-montserrat relative ",
        onclick && "cursor-pointer select-none",
        !noGradient && "bg-gradient-to-r from-primary-main/70 via-primary-main to-yellow bg-clip-text text-transparent",
        understroke &&
          'after:content-[" "] after:w-[120px] after:border-2 after:border-yellow after:rounded-full after:bg-yellow after:absolute after:bottom-0 after:left-0',
        classname
      );
      const H5classes = clx(
            "text-[32px] font-bold font-semibold font-montserrat",
            onclick && 'cursor-pointer select-none',
            classname
      )
      const H6classes = clx(
        "text-[14px] lg:text-[16px] font-bold font-montserrat",
        onclick && "cursor-pointer select-none",
        classname
      );
      const classes = clx(
        "text-[12px] lg:text-[14.5px] font-medium font-montserrat leading-[1.8]",
        onclick && 'cursor-pointer select-none',
        classname
      );
      switch (as) {
        case "h1":
          return <h1 className={H1classes} onClick={onclick}>{children}</h1>;
        case "h2":
          return <h2 className={H2classes} onClick={onclick}>{children}</h2>;
        case "h3":
          return <h3 className={H3classes} onClick={onclick}>{children}</h3>;
        case "h4":
          return <h4 className={H4classes} onClick={onclick}>{children}</h4>;
        case "h5":
          return <h5 className={H5classes} onClick={onclick}>{children}</h5>;
        case "h6":
          return <h6 className={H6classes} onClick={onclick}>{children}</h6>;
        case "p":
          return <p className={classes} onClick={onclick}>{children}</p>;
        case "article":
          return <article className={classes} onClick={onclick}>{children}</article>;
        default:
          return <div className={classes} onClick={onclick}>{children}</div>;
      }
}