import React from 'react'
import { BsPlusCircleFill } from 'react-icons/bs';
import {HiMinusCircle} from 'react-icons/hi2'
import clx from '../utils/clx'
import Text from './text'

export default function Faq({ classname, heading, question, onclick, isOpen, children }) {
      const classes = clx(
            "border- rounded-3xl shadow-lg min-w-full",
            classname
      )
      return (
        <div className={classes}>
          <div className='flex justify-between items-center gap-3 p-4'>
            <Text as='h6' classname=' text-primary-main'>
              {heading}
            </Text>
            <HiMinusCircle
              className={`text-[29px] text-primary-main ${!isOpen && 'hidden'}`}
              onClick={onclick}
            />
            <BsPlusCircleFill
              className={`text-[24px] text-primary-main ${isOpen && 'hidden'}`}
              onClick={onclick}
            />
            {/* <div className='w-[80px] h-[80px] rounded-full shadow-md'></div> */}
          </div>
          {isOpen && (
            <Text as='article' classname='px-4 pb-4 text-primary-main/90 select-none'>
              {question}
            </Text>
          )}
        </div>
      );
}