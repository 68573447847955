import React from "react";
import Button from "../../components/buttons";
import Card from "../../components/card";
import Section from "../../components/section";
import Text from "../../components/text";
import clx from "../../utils/clx";

export default function OurSolutions({ classname, secondary, children }) {
  const classes = clx("", classname);
  return (
    <div className={classes}>
      <Text
        as='h4'
        classname={`text-primary-main text-center w-fit mx-auto ${
          secondary && "from-yellow via-white/70 to-yellow"
        }`}
        understroke
      >
        Our Solutions
      </Text>
      <Text
        as='p'
        classname={`md:w-[50%] mx-auto text-center leading-loose py-4 ${
          secondary ? "text-white/80" : "text-primary-main/80"
        }`}
      >
        Our mission is to simplify your firm’s administration, evaluate risks
        and streamline your processes. Our suite of solutions offers this.
      </Text>
      <div className='w-fit mx-auto'>
        <Button
          variant='white'
          classname='bg-white text-primary-main font-bold'
          route='/our-solutions'
        >
          View Solutions
        </Button>
      </div>
      <div className='lg:py-[30px] shadow-lg rounded-lg lg:rounded-3xl shadow-gray-100/20 p-6 bg-gray-300/50 lg:w-fit mx-auto mt-[50px] grid grid-cols-1 md:grid-cols-2 lg:flex lg:space-x-[40px] justify-center place-items-center gap-x-6 gap-y-6 lg:gap-y-0'>
        <Card
          src='/images/spectra-risk-logo.png'
          imgResizer='w-[180px] h-[50px]'
          route='/spectra-risk'
          content='Spectra risk engine is a fully customisable risk solution aimed at empowering businesses to translate their risk policies and procedure into measurable risk assessments. It is also the backbone which underpins all our solutions'
        />
        <Card
          src='/images/regcomply-logo.png'
          imgResizer='w-[200px] h-[100px]'
          route='/regcomply'
          content='RegComply is a proactive easy to use risk assessment solution with a catalogue regulation which has been formulated into logical assessments to enable businesses to understand their risk exposures'
        />
        <Card
          src='/images/fxiq-logo.png'
          imgResizer='w-[100px] h-[50px]'
          route='/fxiq'
          content='FXIQ is a client lifecycle management system for currency traders and money changers.'
        />
        {/* <Card src='/images/loaniq-logo.png' /> */}
      </div>
    </div>
  );
}
