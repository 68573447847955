import React from "react";
import Aos from "aos";
import 'aos/dist/aos.css'
import clx from "../utils/clx";

export default function Section({
  classname,
      mainClasses,
      background,
  styles,
  animation,
  children,
  withRibbon,
}) {

  React.useEffect(() => {
    Aos.init()
    Aos.refresh()
  })
  const containerClasses = clx("w-full h-full", mainClasses);
  const classes = clx("w-[90%] mx-auto py-8", classname);
  return (
    <div
      data-aos={animation?? 'zoom-in'}
      // data-aos-easing='ease'
      data-aos-delay='400'
      className={containerClasses}
      style={{ styles }}
    >
      {withRibbon && (
        <div className='absolute inset-0 w-full h-fit overflow-hidden'>
          <div className='h-[50px] bg-primary-light rotate-[3deg] relative right-[-50%] translate-x-[-1%]'></div>
          <div className='h-[50px] bg-primary-main rotate-[-3deg] relative left-[-50%] mt-[-50px] translate-x-[1%]'></div>
        </div>
      )}
      <div className={classes}>{children}</div>
      {/* {withRibbon && (
        <div className='absolute bottom-0 w-full h-fit overflow-hidden rotate-y-180'>
          <div className='h-[50px] bg-primary-light rotate-[3deg] relative right-[-50%] translate-x-[-1%]'></div>
          <div className='h-[50px] bg-primary-main rotate-[-3deg] relative left-[-50%] mt-[-50px] translate-x-[1%]'></div>
        </div>
      )} */}
    </div>
  );
}
