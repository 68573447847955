import React from "react";
import Button from "../components/buttons";
import Card from "../components/card";
import Faq from "../components/faq";
import Section from "../components/section";
import Text from "../components/text";
import { FAQS } from "../constants/faqs";
import OurFaqs from "./components/faqs";
import OurSolutions from "./components/our-solutions";
import Layout from "./layout/layout";

export default function HomepageWrapper() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Layout>
      <Section
        mainClasses="relative min-h-[calc(100vh-80px)] md:min-h-[400px] lg:min-h-[calc(100vh-120px)] bg-primary-main overflow-hidden rounded-br-[50px]"
        classname=""
      >
        {/* Bubbles */}
        <div className="absolute inset-0 w-full h-full overflow-hidden opacity-[3%]">
          <img
            src="/images/bubble-gif.gif"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        {/* Background Image */}
        <div className="absolute inset-0 w-full h-full overflow-hidden opacity-10">
          <img
            src="/images/about-img.jpg"
            alt=""
            className="w-full h-full object-cover hidden lg:block"
          />
          <img
            src="/images/solution-bg2.jpg"
            alt=""
            className="w-full h-full object-cover lg:hidden"
          />
        </div>
        {/* Ring */}
        <div className="absolute right-[-50px] bottom-[-50px] w-[150px] h-[150px] lg:w-[200px] lg:h-[200px] rounded-full border-4 border-l-yellow"></div>

        <div className="absolute inset-0 w-[90%] mx-auto py-[60px] lg:py-[100px] lg:flex flex-col lg:items-center justify-center">
          {/* <Text
            as='h4'
            classname='text-white text-[14px] lg:text-[16px] from-yellow via-white/70 w-fit'
          >
            Risk Solutions Expert
          </Text> */}
          <Text
            as="h1"
            classname="text-white lg:text-center lg:text-[40px] lg:w-[70%] mx-auto font-fredoka font-[700]"
          >
            INTELLIGENT CUSTOMER LIFECYCLE MANAGEMENT AND DECISIONING
          </Text>
          <Text
            as="article"
            classname="w-[90%] lg:w-[40%] lg:mx-auto lg:text-center py-[20px] text-white/80"
          >
            Spectra risk creates customer lifecycle and decisioning solutions
            underpinned by governance, risk and compliance
          </Text>
          {/* <div className='flex gap-x-6 w-fit lg:mx-auto'>
            <Button variant='white' classname='font-bold' route='/about-us'>
              About Us
            </Button>
            <Button
              classname='font-bold'
              variant='gradient'
              route='/contact-us'
            >
              Contact Us
            </Button>
          </div> */}
          <div className="h-[60px] mt-[20px] w-[20px] lg:mx-auto">
            <img
              src="/images/arrow-down-gif2.gif"
              alt=""
              className="w-full h-full"
            />
          </div>
        </div>
      </Section>
      <Section classname="py-[50px] lg:py-[80px] flex flex-col md:flex-row items-center gap-y-14 lg:gap-y-0">
        <div className="flex-1">
          <div className="w-[90%]">
            <Text as="h4" classname="w-fit" understroke>
              Improve your business efficiency and gain better insights into
              your risks.
            </Text>
            <Text as="p" classname="text-primary-main/90 py-4">
              Our CLM solution can address businesses' challenges in managing
              operations and risks, improving efficiency and unlocking growth
              opportunities.
            </Text>
            {/* <Text as="p" classname="text-primary-main/90 py-4">
              We also ensure a clear view of inherent risk posed by customers,
              and their transactions are determined by all the solutions we
              develop.
            </Text> */}
            {/* <Button route="/about-us">More About Us</Button> */}
          </div>
        </div>
        <div className="flex-1 flex justify-end">
          <div className="lg:h-[450px] lg:w-full rounded-[10px] overflow-hidden">
            <img src="/images/image16.jpg" alt="" className="h-full w-full" />
          </div>
        </div>
      </Section>
      <Section
        mainClasses="bg-gray-200"
        classname="py-[50px] lg:py-[80px] flex flex-col md:flex-row-reverse items-center gap-y-14 lg:gap-y-0"
      >
        <div className="flex-1">
          <div className="w-[90%] md:ml-auto">
            <Text as="h4" classname="w-fit" understroke>
              Streamline your business operations by automating your manual
              processes.
            </Text>
            <Text as="p" classname="text-primary-main/90 py-4">
              Our front-to-back solution frees businesses from spending time on
              data entry and administrative tasks, allowing them to prioritise
              profit-generating tasks.
            </Text>
            {/* <Text as="p" classname="text-primary-main/90 py-4">
              We also ensure a clear view of inherent risk posed by customers,
              and their transactions are determined by all the solutions we
              develop.
            </Text> */}
            {/* <Button route="/about-us">More About Us</Button> */}
          </div>
        </div>
        <div className="flex-1 flex justify-end">
          <div className="lg:h-[450px] lg:w-full rounded-[10px] overflow-hidden">
            <img src="/images/image18.jpg" alt="" className="h-full w-full" />
          </div>
        </div>
      </Section>

      <Section classname="py-[50px] lg:py-[80px] flex flex-col md:flex-row items-center gap-y-14 lg:gap-y-0">
        <div className="flex-1">
          <div className="w-[90%]">
            <Text as="h4" classname="w-fit" understroke>
              Increased confidence in managing complex risks and regulatory
              compliance.
            </Text>
            <Text as="p" classname="text-primary-main/90 py-4">
              Leverage our innovative software solutions and industry-leading
              expertise to evaluate regulatory risk.
            </Text>
            {/* <Text as="p" classname="text-primary-main/90 py-4">
              We also ensure a clear view of inherent risk posed by customers,
              and their transactions are determined by all the solutions we
              develop.
            </Text> */}
            {/* <Button route="/about-us">More About Us</Button> */}
          </div>
        </div>
        <div className="flex-1 flex justify-end">
          <div className="lg:h-[450px] lg:w-full rounded-[10px] overflow-hidden">
            <img src="/images/image14.png" alt="" className="h-full w-full" />
          </div>
        </div>
      </Section>
      <Section
        mainClasses="bg-gray-200"
        classname="py-[50px] lg:py-[80px] flex flex-col md:flex-row-reverse items-center gap-y-14 lg:gap-y-0"
      >
        <div className="flex-1">
          <div className="w-[90%] md:ml-auto">
            <Text as="h4" classname="w-fit" understroke>
              Improve financial control with advanced financial management tools
            </Text>
            <Text as="p" classname="text-primary-main/90 py-4">
              Maximize your visibility into currency exchange activities,
              minimize errors, and optimize profitability by utilizing our
              solution.
            </Text>
            {/* <Text as="p" classname="text-primary-main/90 py-4">
              We also ensure a clear view of inherent risk posed by customers,
              and their transactions are determined by all the solutions we
              develop.
            </Text> */}
            {/* <Button route="/about-us">More About Us</Button> */}
          </div>
        </div>
        <div className="flex-1 flex justify-end">
          <div className="lg:h-[450px] lg:w-full rounded-[10px] overflow-hidden">
            <img src="/images/image15.jpg" alt="" className="h-full w-full" />
          </div>
        </div>
      </Section>

      {/* <Section mainClasses="bg-primary-main" classname="py-[80px]">
        <OurSolutions secondary />
      </Section> */}
      {/* <Section classname="py-[50px] lg:py-[120px] flex gap-y-8 gap-x-6 items-center flex-col-reverse md:flex-row">
        <div className="flex-1">
          <div className="lg:h-[450px] lg:w-[450px]">
            <img src="/images/hero-img.png" alt="" className="h-full w-full" />
          </div>
        </div>
        <div className="flex-1">
          <Text as="h4" classname="w-fit" understroke>
            Why Choose US
          </Text>
          <Text as="p" classname="text-primary-main/90 py-3">
            At Spectra risk, our approach sets us apart. We design our
            applications jointly with impacted stakeholders to ensure we are
            solving the right problems the correct way.
          </Text>
          <Text as="p" classname="text-primary-main/90 py-3">
            At the helm of our leadership are professionals with over twenty
            years of combined experience building solutions and reengineering
            processes at tier-one investment Banks and fintech across the globe.
            If this isn't enough reason to choose us, contact us for a demo to
            see any of our tools live in action.
          </Text>
          <Button route="/contact-us">Get In Touch</Button>
        </div>
      </Section> */}
      <Section classname="py-[80px] lg:flex items-center justify-center flex-col">
        <Text as="h4" classname="w-fit" understroke>
          Use Cases
        </Text>
        {/* <Text as="p" classname="py-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
          exercitationem.
        </Text> */}
        <div className="p-[20px] lg:p-[50px] shadow-lg rounded-3xl border- border-primary-main/10 bg-gradient-to-b from-transparent to-primary-main/5 mt-[10px]">
          <div className="flex flex-row gap-6 lg:grid grid-cols-3 items-center overflow-auto scrollbar scrollbar-thumb-primary-main scrollbar-thumb-rounded-full scrollbar-track-yellow/30 py-6">
            <Card
              type="secondary"
              src="/images/statistics.png"
              imgResizer="w-[80px]"
              label="Risk Assessments"
              description="Pre-configured and bespoke risk assessments"
            />
            <Card
              type="secondary"
              src="/images/smartphone.png"
              imgResizer="w-[80px]"
              label="Identity Verification"
              description="Automate ID&V checks available in the UK and Nigeria"
            />
            <Card
              type="secondary"
              src="/images/analytics.png"
              imgResizer="w-[80px]"
              label="FX Business management"
              description="Front-to-back management of currency exchange business"
            />
            {/* <Card
              type="secondary"
              src="/images/pie-chart2.png"
              imgResizer="w-[80px]"
              label="Risk Assessments"
              description="Front-to-back management of currency exchange business"
            /> */}
          </div>
        </div>
        {/* <Button classname="mx-auto mt-[30px]">Our Solutions</Button> */}
      </Section>
      {/* <Section classname='flex flex-col-reverse md:flex-row py-[80px] lg:py-[120px] gap-y-8 md:gap-x-8'>
        <div className='flex-1'>
          <Text as='h4' classname='w-fit'>
            Frequently Asked Questions
          </Text>
          <div className='py-[30px] flex flex-col gap-y-6'>
            <OurFaqs />
          </div>
          <Button>View All FAQs</Button>
        </div>
        <div className='lg:w-[40%] flex justify-center lg:justify-end md:items-center'>
          <div className='w-[270px] h-[270px] lg:w-[400px] lg:h-[420px]'>
            <img
              src='/images/faq2.png'
              alt=''
              className='w-full h-full objet-fill'
            />
          </div>
        </div>
      </Section> */}
      {/* <Section classname='py-[80px]'>
        <Text as='h4' classname='w-fit mx-auto'>
          Our Partnering Brands
        </Text>
        <div className='grid grid-cols-3 gap-6 lg:grid-cols-5 place-items-center py-[50px]'>
          <div>
            <img src='/images/chase-logo.png' alt='' />
          </div>
          <div>
            <img src='/images/asana-logo.png' alt='' />
          </div>
          <div>
            <img src='/images/google-logo.png' alt='' />
          </div>
          <div>
            <img src='/images/buzzfeed-logo.png' alt='' />
          </div>
          <div>
            <img src='/images/walmart-logo.png' alt='' />
          </div>
        </div>
      </Section> */}
    </Layout>
  );
}
