import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import clx from "../../utils/clx";

export default function Layout({ classname, children }) {
  const classes = clx("w-full min-screen", classname);
  return (
    <div className={classes}>
      <Header />
      <div className='flex flex-col min-h-[calc(100vh-80px)] lg:min-h-[calc(100vh-120px)] mt-[80px] lg:mt-[120px] bg-gradient-to-br from-gray-50 via-gray-50 to-gray-100'>
        <div className='flex-1'>{children}</div>
        <Footer />
      </div>
    </div>
  );
}
