import React from "react";
import clx from "../utils/clx";
import Text from "./text";

export default function TextArea({
  classname,
  name,
  placeholder,
  label,
  onchange,
  onblur,
  value,
  error,
  children,
}) {
  const classes = clx("w-full ", classname);
  return (
    <div className={classes}>
      <label htmlFor={name} className=''>
        <Text as='p' classname='font-[705] text-primary-main'>
          {label}
        </Text>
      </label>
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onchange}
        onBlur={onblur}
        value={value}
        className={`outline-none border-b-2 ${error? "border-red-500":"border-primary-main"} w-full pt-3 h-[100px] bg-transparent`}
      ></textarea>
    </div>
  );
}
