import React from "react";
import { useLocation } from "react-router-dom";
import {RiMenu4Line} from 'react-icons/ri'
import { Link } from "react-router-dom";
import { navLinks } from "../constants/navlinks";
import clx from "../utils/clx";
import Button from "./buttons";
import Section from "./section";
import Text from "./text";

export default function Header({ classname, children }) {
  const router = useLocation()
  const [isMenuOpen, setMenuOpen] = React.useState(false)
  const classes = clx(
    "flex justify-between items-center lg:py-4 w-[90%] mx-auto h-[80px] lg:h-[120px]",
    classname
  );
  return (
    <div className='w-full h-fit bg-white fixed inset-0 z-[999] shadow-sm lg:shadow-none'>
      <div className={classes}>
        <div className='flex items-center'>
          <div className='w-[130px] lg:w-[200px] h-[45px] lg:h-[70px]'>
            <img
              src='/images/logo.png'
              alt='spectra-risk-logo'
              className='w-full h-full object-fill'
            />
          </div>
        </div>
        <div className='lg:w-[50%] xl:w-[40%]'>
          <ul className='hidden lg:flex gap-6 items-center justify-between'>
            {navLinks.map((link) => (
              <Text
                key={link.id}
                as='p'
                classname={`font-[600] w-fit ${
                  link.route === router.pathname
                    ? "bg-gradient-to-r from-primary-main via-primary-main/70 to-yellow bg-clip-text text-transparent"
                    : "text-primary-main"
                }`}
              >
                <Link to={link.route}>{link.label}</Link>
              </Text>
            ))}
          </ul>
        </div>
        {/* Mobile Hamburger */}
        <div
          className='lg:hidden min-w-[35px] min-h-[35px] rounded-full bg-primary-main flex items-center justify-center p-2'
          onClick={() => setMenuOpen(true)}
        >
          <RiMenu4Line className='text-[18px] text-white' />
        </div>
      </div>

      {/* Side Menu */}
      <div
        className={`lg:hidden fixed inset-0 w-full h-screen bg-black/5 ${
          isMenuOpen ? "ml-0" : "ml-[-100%]"
        } duration-300`}
        onClick={({ target, currentTarget }) => {
          target === currentTarget && setMenuOpen(false);
        }}
      >
        <div className='w-[60%] md:w-[30%] h-full bg-white p-4 relative overflow-hidden'>
          <div className='w-[130px] lg:w-[200px] h-[45px] lg:h-[70px]'>
            <img
              src='/images/logo.png'
              alt='spectra-risk-logo'
              className='w-full h-full object-fill'
            />
          </div>
          <div className='py-[50px]'>
            <ul className='lg:hidden flex flex-col gap-6'>
              {navLinks.map((link) => (
                <Link
                  key={link.id}
                  to={link.route}
                  onClick={() => setMenuOpen(false)}
                >
                  <Text
                    as='h6'
                    classname={`font-[700] w-fit ${
                      link.route === router.pathname
                        ? "bg-gradient-to-r from-primary-main via-primary-main/70 to-yellow bg-clip-text text-transparent"
                        : "text-primary-main"
                    }`}
                  >
                    {link.label}
                  </Text>
                </Link>
              ))}
            </ul>
          </div>
          {/* <div>
            <Text as='h6' classname='py-4 text-primary-main'>
              Need Help?
            </Text>
            <Button route='/contact-us'>Contact us</Button>
          </div> */}

          <div className='absolute bottom-[-50px] right-[-50px] w-[150px] h-[150px] rounded-full border-4 ring-4 ring-primary-main ring-offset-4 border-t-primary-main border-l-yellow bg-primary-main'></div>
        </div>
      </div>
    </div>
  );
}
