import React from 'react'
import Card from '../components/card'
import Footer from '../components/footer'
import Header from '../components/header'
import Layout from '../views/layout/layout'

export default function Test() {
      return (
            <div>
                  <Layout>hhdskjjhsd</Layout>
                  {/* <Header />
                  <Footer /> */}
            </div>
      )
}