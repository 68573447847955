import React from "react";
import { useFormik } from "formik";
import Button from "../../components/buttons";
import Input from "../../components/input";
import TextArea from "../../components/textarea";
import * as Yup from "yup";
import { submitForm } from "../../utils/submit-form";
import { toast } from "react-toastify";

export default function ContactForm() {
  const [validationAttempt, setValidationAttempt] = React.useState(false);

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Enter First Name"),
    lastname: Yup.string().required("Enter First Name"),
    email: Yup.string().email("Enter valid email").required("Enter First Name"),
    phone: Yup.number().required("Enter Phone"),
    subject: Yup.string().required("Enter Subject"),
    message: Yup.string().required("Enter Message"),
  });
  const onSubmit = async (values, { resetForm }) => {
    setValidationAttempt(false);
    await submitForm(values)
      .then((res) => {
        toast.success("Message Sent Successfully!");
        resetForm()
      })
      .catch((err) => {
        toast.error(
          "There was an error sending your message. Please try again."
        );
      });
    // console.log(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit} className="">
      <div className="grid grid-cols-2 gap-x-10">
        <Input
          type="text"
          label="First Name"
          name="firstname"
          placeholder="Enter first name"
          onblur={formik.handleBlur}
          onchange={formik.handleChange}
          value={formik.values.firstname}
          error={formik.errors.firstname}
          classname="py-5"
        />
        <Input
          type="text"
          label="Last Name"
          name="lastname"
          placeholder="Enter last name"
          onblur={formik.handleBlur}
          onchange={formik.handleChange}
          value={formik.values.lastname}
          error={formik.errors.lastname}
          classname="py-5"
        />
        <Input
          type="email"
          label="Email"
          name="email"
          placeholder="example@gmail.com"
          onblur={formik.handleBlur}
          onchange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
          classname="py-5"
        />
        <Input
          type="phone"
          label="Phone Number"
          name="phone"
          placeholder="Enter phone number"
          onblur={formik.handleBlur}
          onchange={formik.handleChange}
          value={formik.values.phone}
          error={formik.errors.phone}
          classname="py-5"
        />
      </div>
      <div className="py-5">
        <Input
          type="text"
          label="Subject"
          name="subject"
          placeholder="Enter Subject"
          onblur={formik.handleBlur}
          onchange={formik.handleChange}
          value={formik.values.subject}
          error={formik.errors.subject}
          classname="py-5"
        />
        <TextArea
          label="Your Message"
          name="message"
          placeholder="Enter your message here"
          onblur={formik.handleBlur}
          onchange={formik.handleChange}
          value={formik.values.message}
          error={formik.errors.message}
          classname="py-5"
        />
      </div>
      <Button type="submit" onclick={() => setValidationAttempt(true)}>
        Send Message
      </Button>
    </form>
  );
}
