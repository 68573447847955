import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/home";
import Test from "./pages/test";
import AboutUs from "./pages/about";
import ContactUs from "./pages/contact";
import Solutions from "./pages/solutions";
import Regcomply from "./pages/regcomply";
import FXIQ from "./pages/fxiq";
import SpectraRisk from "./pages/spectrarisk";
import FakeHome from "./views/fake-home";
import React from "react";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/our-solutions' element={<Solutions />} />
        <Route path='/regcomply' element={<Regcomply />} />
        <Route path='/fxiq' element={<FXIQ />} />
        <Route path='/spectra-risk' element={<SpectraRisk />} />
        <Route path='/test' element={<Test />} />
        <Route path='/home' element={<FakeHome />} />
      </Routes>
    </Router>
  );
}

export default App;
