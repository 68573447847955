import React from "react";
import { BsArrowUp } from "react-icons/bs";
import { navLinks } from "../constants/navlinks";
import { socials } from "../constants/socials";
import clx from "../utils/clx";
import Section from "./section";
import Text from "./text";
import { Link } from "react-router-dom";

export default function Footer({ classname, children }) {
  const classes = clx(
    "lg:grid grid-cols-4 gap-x-10 py-[50px] relative",
    classname
  );
  return (
    <React.Fragment>
      <Section
        animation="none"
        mainClasses="bg-primary-main"
        classname={classes}
      >
        {/* Scroll To top */}
        {/* <div className=" absolute flex items-center justify-center right-[10px] lg:right-0 top-[30px] w-[45px] h-[45px] rounded-full bg-yellow cursor-pointer">
          <BsArrowUp className="font-extrabold text-2xl text-white" />
        </div> */}
        {/* End of scroll to top */}
        <div className="">
          <div className="w-[130px] lg:w-[200px] h-[45px] lg:h-[70px]">
            <img
              src="/images/logo-white.png"
              alt="spectra-risk-logo"
              className="w-full h-full"
            />
          </div>
          <Text as='article' classname='text-white/80 py-3 font-[400]'>
           We build software solutions that automates manual jobs and help businesses easily access and quantify their risk exposure
          </Text>
        </div>
        <div className="text-white/80 pt-6 lg:pt-0">
          <Text as="p" classname="pb-4">
            124 City Road, London, England, EC1V 2NX
          </Text>
          <div className="flex lg:block gap-8">
            <Text as="p" classname="py-2">
              info@spectrarisk.io
            </Text>
            <Text as="p" classname="py-2">
              +44 333 011 9210
            </Text>
          </div>
        </div>
        <div className="lg:pl-14 py-6 lg:py-0">
          <Text as="h6" classname="font-bold text-[14px] text-yellow pb-3">
            Quick Links
          </Text>
          <ul className="flex flex-col gap-3 text-white/80">
            {navLinks.map((link) => (
              <Link key={link.label} to={link.route}>
              <Text key={link.id} as="p">
                {link.label}
              </Text>
              </Link>
            ))}
          </ul>
        </div>
        <div className="pl-14 hidden lg:block">
          <Text as="h6" classname="font-bold text-[14px] text-yellow pb-3">
            Socials
          </Text>
          <ul className="flex flex-col gap-3 text-white/80">
            {socials.map((social) => (
              <a key={social.label} href={social.href} target="_blank" rel="noreferrer">
              <Text key={social.id} as="p">
                {social.label}
              </Text>
              </a>
            ))}
          </ul>
        </div>
      </Section>
      <div className="bg-primary-main/5 py-[10px] lg:py-5 text-center">
        <Text as="p" classname="font-semibold">
          © {new Date().getFullYear()} Spectra Risk Solutions. All rights
          reserved
        </Text>
      </div>
    </React.Fragment>
  );
}
