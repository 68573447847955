import React from "react";
import { Link } from "react-router-dom";
import clx from "../utils/clx";

export default function Button({ classname, children, onclick, variant = "primary", route, type }) {
  const classes = clx(
    "text-center w-fit py-4 lg:py-5 px-8 lg:px-16 rounded-xl text-[13px] lg:text-[15px] tracking-wider cursor-pointer",
    variant === "primary" &&
      "bg-primary-main hover:bg-primary-main/90 duration-300 text-white font-semibold",
    variant === "gradient" &&
      "bg-gradient-to-r from-white via-white/60 to-yellow hover:bg-primary-main/90 bg-yellow duration-300 text-primary-main font-semibold",
    variant === "white" &&
      "bg-white/90 hover:bg-white/70 duration-300 text-primary-main font-bold",
    classname
  );
  if (route) {
    return (
      <Link to={route}>
        <button type={type} className={classes} onClick={onclick}>{children}</button>
      </Link>
    );
    
  }
      return <button className={classes} type={type} onClick={onclick}>{children}</button>;
}
