import React from "react";
import clx from "../utils/clx";
import Text from "./text";

export default function Input({
  classname,
  name,
  placeholder,
  label,
  onchange,
  error,
  onblur,
  value,
  type,
  children,
}) {
  const classes = clx("w-full", classname);
  return (
    <div className={classes}>
      <label htmlFor={name} className=''>
        <Text as='p' classname='font-[705] text-primary-main'>
          {label}
        </Text>
      </label>
      <input
        type={type}
        name={name}
        id={name}
        onChange={onchange}
        onBlur={onblur}
        placeholder={placeholder}
        value={value}
        className={`outline-none border-b-2 ${error?"border-red-500":"border-primary-main"} w-full py-2 pt-3 bg-transparent`}
      />
    </div>
  );
}
