/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";
import { BsFacebook } from "react-icons/bs";
import { CgPhone } from "react-icons/cg";
import { GrMail } from "react-icons/gr";
import { IoLogoLinkedin } from "react-icons/io";
import { AiFillTwitterCircle } from "react-icons/ai";
import { RiInstagramFill } from "react-icons/ri";
import { ImLocation2 } from "react-icons/im";
import Button from "../components/buttons";
import Faq from "../components/faq";
import Input from "../components/input";
import Label from "../components/label";
import Radio from "../components/radio";
import Section from "../components/section";
import Text from "../components/text";
import TextArea from "../components/textarea";
import clx from "../utils/clx";
import Layout from "./layout/layout";
import ContactForm from "./components/contact-form";

export default function ContactWrapper({ classname, children }) {
  const classes = clx("", classname);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Layout>
      <Section
        mainClasses="relative min-h-[350px] lg:min-h-[420px] border-b-2 border-yellow/30  bg-primary-main"
        classname=""
      >
        {/* Transparent Background */}
        <div className="absolute inset-0 w-full h-full overflow-hidden opacity-20">
          <img
            src="/images/mesh3.jpg"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        {/* <div className='top-[10px] right-[5%] absolute w-[180px] h-[180px] rounded-full border-2 border-yellow'></div>
        <div className='bottom-[10px] left-[5%] absolute w-[180px] h-[180px] rounded-full border-2 border-primary-main'></div>
        <div className='hidden absolute w-[80%] left-[10%] right-[10%] border-b-2 border-r-2 rounded-3xl border-primary-main border-r-yellow h-[400px]'></div> */}
        <div className="absolute inset-0 left-0 w-[90%] mx-auto h-full py-[60px] lg:py-[80px] flex flex-col items-center justify-center">
          <Text as="h1" classname="text-left md:text-center text-white">
            Contact Us
          </Text>
          {/* <Text
            as="article"
            classname="md:w-[40%] mx-auto text-left md:text-center py-[20px] text-white/80"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quam
            tempus, varius habitasse tellus. Purus mauris vitae egestas commodo
            feugiat tellus interdum. Diam rhoncus, neque,
          </Text> */}
          {/* <div className='md:w-fit mx-auto flex gap-x-3 lg:gap-x-6 items-center'>
            <Button classname='hover:bg-primary-main/60'
            route='/about-us'>About Us</Button>
            <Button variant="gradient" classname='font-bold'>
              Our FAQs
            </Button>
          </div> */}
          <div className="h-[60px] w-[20px] mx-auto">
            <img
              src="/images/arrow-down-gif2.gif"
              alt=""
              className="w-full h-full"
            />
          </div>
        </div>
      </Section>
      <Section classname="flex flex-col-reverse lg:flex-row gap-6 py-[30px] lg:py-[80px]">
        <div className="flex-1">
          <div className="w-[95%]">
            {/* <Text
              as='h4'
              classname='text-primary-main lg:text-[30px] py-4 my-3'
              understroke
            >
              Frequently Asked Questions
            </Text>
            <div className='flex flex-col gap-3'>
              <Faq />
              <Faq />
              <Faq />
              <Faq />
            </div>
            <Button classname='my-6'>See All FAQs</Button> */}
            <div className="py-4">
              <Text
                as="h4"
                classname="text-[19px] text-primary-main my-6 font-bold"
                understroke
              >
                Contact Info
              </Text>
              <div className="grid grid-cols-2 gap-6">
                <Label
                  icon={
                    <CgPhone className="min-w-full min-h-full text-primary-main" />
                  }
                >
                  +44 333 011 9210
                </Label>
                <Label
                  icon={
                    <GrMail className="min-w-full min-h-full text-primary-main" />
                  }
                >
                  info@spectrarisk.io
                </Label>
                <Label
                  icon={
                    <ImLocation2 className="min-w-full min-h-full text-primary-main" />
                  }
                >
                  124 City Road, London, England, EC1V 2NX
                </Label>
              </div>
              <div className="flex gap-10 items-center py-6">
                <a href="https://www.facebook.com/spectrarisk?mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                  <BsFacebook className="text-[30px] text-primary-main" />
                </a>
                {/* <RiInstagramFill className='text-[30px] text-primary-main' /> */}
                <a href="https://twitter.com/spectrarisk?s=11&t=kXx1gzJc9sbQTWoyKEHORA" target="_blank" rel="noreferrer">
                  <AiFillTwitterCircle className="text-[30px] text-primary-main" />
                </a>
                <a href="https://www.linkedin.com/company/spectra-risk-solutions/" target="_blank" rel="noreferrer">
                  <IoLogoLinkedin className="text-[30px] text-primary-main" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <ContactForm />
        </div>
      </Section>
    </Layout>
  );
}
