import React from 'react'
import Button from '../components/buttons';
import Card from '../components/card';
import Faq from '../components/faq';
import Section from '../components/section'
import Text from '../components/text';
import OurSolutions from './components/our-solutions';
import Layout from './layout/layout'

export default function FakeHome({ classname, children }) {
      return (
        <Layout>
          <Section
            mainClasses='relative min-h-[calc(100vh-80px)] lg:min-h-[calc(100vh-120px)] bg-primary-main overflow-hidden rounded-br-[50px]'
            classname=''
          >
            {/* Bubbles */}
            <div className='absolute inset-0 w-full h-full overflow-hidden opacity-[3%]'>
              <img
                src='/images/bubble-gif.gif'
                alt=''
                className='w-full h-full object-cover'
              />
            </div>
            {/* Background Image */}
            <div className='absolute inset-0 w-full h-full overflow-hidden opacity-10'>
              <img
                src='/images/about-img.jpg'
                alt=''
                className='w-full h-full object-cover hidden lg:block'
              />
              <img
                src='/images/solution-bg2.jpg'
                alt=''
                className='w-full h-full object-cover lg:hidden'
              />
            </div>
            {/* Ring */}
            <div className='absolute right-[-50px] bottom-[-50px] w-[150px] h-[150px] lg:w-[200px] lg:h-[200px] rounded-full border-4 border-l-yellow'></div>

            <div className='absolute inset-0 py-[60px] lg:py-[100px] px-3 lg:px-0 lg:flex flex-col lg:items-center justify-center'>
              <Text
                as='h4'
                classname='text-white text-[14px] lg:text-[16px] from-white via-white/70 w-fit'
              >
                Risk Solutions Expert
              </Text>
              <Text
                as='h1'
                classname='text-white lg:text-center lg:text-[40px] lg:w-[70%] mx-auto font-fredoka font-[700]'
              >
                RISK SOLUTIONS TO REDUCE FINACIAL CRIMES IN THE MARKET
              </Text>
              <Text
                as='article'
                classname='w-[90%] lg:w-[40%] lg:mx-auto lg:text-center py-[20px] text-white/80'
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem autem cumque voluptatum quia.
              </Text>
              <div className='flex gap-x-6 w-fit lg:mx-auto'>
                <Button classname='bg-white text-primary-main font-bold'>
                  About Us
                </Button>
                <Button
                  classname='bg-yellow text-primary-main font-bold'
                  variant='secondary'
                >
                  Contact Us
                </Button>
              </div>
              <div className='h-[60px] mt-[20px] w-[20px] lg:mx-auto'>
                <img
                  src='/images/arrow-down-gif2.gif'
                  alt=''
                  className='w-full h-full'
                />
              </div>
            </div>
          </Section>
          <Section classname='py-[50px] lg:py-[80px] flex flex-col lg:flex-row items-center gap-y-14 lg:gap-y-0'>
            <div className='flex-1'>
              <Text as='h4' classname='w-fit' understroke>
                What We Do
              </Text>
              <Text as='p' classname='text-primary-main/90 py-4'>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Autem
                excepturi saepe vel, nisi voluptatem dolores est harum ipsam
                perspiciatis culpa sint, numquam delectus reiciendis eaque
                perferendis eius fugit quae provident. numquam delectus
                reiciendis eaque perferendis eius fugit quae provident.
              </Text>
              <Text as='p' classname='text-primary-main/90 py-4'>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Autem
                excepturi saepe vel, nisi voluptatem dolores est harum ipsam
                perspiciatis culpa sint, numquam delectus reiciendis eaque
                perferendis eius fugit quae provident. numquam delectus
                reiciendis eaque perferendis eius fugit quae provident.
              </Text>
              <Button>More About Us</Button>
            </div>
            <div className='flex-1 flex justify-end'>
              <div className='lg:h-[450px] lg:w-[450px]'>
                <img
                  src='/images/hero-img.png'
                  alt=''
                  className='h-full w-full'
                />
              </div>
            </div>
          </Section>
          <Section mainClasses='bg-primary-main' classname='py-[80px]'>
            <OurSolutions secondary />
          </Section>
          <Section classname='py-[50px] lg:py-[120px] flex flex-col-reverse lg:flex-row'>
            <div className='flex-1'></div>
            <div className='flex-1'>
              <Text as='h4' classname='w-fit' understroke>
                Why Choose US
              </Text>
              <Text as='p' classname='text-primary-main/90 py-3'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                mauris lobortis eu ultrices mi. Volutpat suscipit ultricies
                cursus feugiat. Neque pharetra volutpat quis morbi mattis
                accumsan. Posuere molestie placerat accumsan ultricies risus,
                malesuada amet nascetur. Sagittis tempus vitae, odio volutpat
                etiam facilisis dolor mauris neque. Volutpat, molestie id quis
                blandit at mauris velit. Quis molestie adipiscing at cras ut in
                adipiscing massa pretium.
              </Text>
              <Text as='p' classname='text-primary-main/90 py-3'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                mauris lobortis eu ultrices mi. Volutpat suscipit ultricies
                cursus feugiat. Neque pharetra volutpat quis morbi mattis
                accumsan. Posuere molestie placerat accumsan ultricies risus,
                malesuada amet nascetur. Sagittis tempus vitae, odio volutpat
                etiam facilisis dolor mauris neque. Volutpat, molestie id quis
                blandit at mauris velit. Quis molestie adipiscing at cras ut in
                adipiscing massa pretium.
              </Text>
              <Button>Get In Touch</Button>
            </div>
          </Section>
          <Section classname='py-[80px]'>
            <Text as='h4' classname='w-fit' understroke>
              Use Cases
            </Text>
            <Text as='p' classname='py-4'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
              exercitationem.
            </Text>
            <div className='p-[50px] shadow-lg rounded-3xl border- border-primary-main/10 bg-gradient-to-b from-transparent to-primary-main/5 mt-[10px] grid grid-cols-1 lg:grid-cols-4 place-items-center gap-x-6 gap-y-6 lg:gap-y-0'>
              <Card
                type='secondary'
                src='/images/usecase-img1.png'
                imgResizer='w-[80px]'
              />
              <Card
                type='secondary'
                src='/images/usecase-img1.png'
                imgResizer='w-[80px]'
              />
              <Card
                type='secondary'
                src='/images/usecase-img1.png'
                imgResizer='w-[80px]'
              />
              <Card
                type='secondary'
                src='/images/usecase-img1.png'
                imgResizer='w-[80px]'
              />
            </div>
          </Section>
          <Section classname='flex flex-col-reverse lg:flex-row py-[50px] lg:py-[80px]'>
            <div className='flex-1'>
              <Text as='h4' classname='w-fit'>
                Frequently Asked Questions
              </Text>
              <div className='py-[30px] flex flex-col gap-y-6'>
                <Faq />
                <Faq />
                <Faq />
                <Faq />
              </div>
              <Button>View All FAQs</Button>
            </div>
            <div className='w-[40%]'></div>
          </Section>
          <Section classname='py-[80px]'>
            <Text as='h4' classname='w-fit mx-auto'>
              Our Partnering Brands
            </Text>
            <div className='grid grid-cols-3 gap-6 lg:grid-cols-5 place-items-center py-[50px]'>
              <div>
                <img src='/images/chase-logo.png' alt='' />
              </div>
              <div>
                <img src='/images/asana-logo.png' alt='' />
              </div>
              <div>
                <img src='/images/google-logo.png' alt='' />
              </div>
              <div>
                <img src='/images/buzzfeed-logo.png' alt='' />
              </div>
              <div>
                <img src='/images/walmart-logo.png' alt='' />
              </div>
            </div>
          </Section>
        </Layout>
      );
}