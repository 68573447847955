import axios from "axios";

const portalId = 26005029;
const formGuid = "11e1ce7d-cdd6-4144-8045-029101b21cac";
const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;
export const submitForm = async (values) =>
  axios.post(
    endpoint,
    {
      portalId,
      formGuid,
      fields: [
        {
          name: "firstname",
          value: values.firstname,
        },
        {
          name: "lastname",
          value: values.lastname,
        },
        {
          name: "email",
          value: values.email,
        },
        {
          name: "phone",
          value: values.phone,
        },
        {
          name: "subject",
          value: values.subject,
        },
        {
          name: "message",
          value: values.message,
        },
      ],
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
