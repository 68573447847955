import React from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import ReactDOM from 'react-dom'
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa'
import clx from '../utils/clx'

export default function LightBox({ classname, children, isOpen, setOpen }) {
      const classes = clx(
            "fixed inset-0 z-[999] min-w-full min-h-full bg-black/30 flex justify-center items-center duration-300 overflow-hidden",
            isOpen? 'flex':'hidden',
            classname
      )

      React.useEffect(() => {
            Aos.init()
      })
      return ReactDOM.createPortal(
        <div data-aos='flip-right'  className={classes} onClick={()=>setOpen(false)}>
          <div className='w-[300px] h-[300px] md:w-[650px] md:h-[500px] border-2 bg-primary-main rounded-3xl relative'>
            {/* Controls */}
            <div className='absolute top-[50%] translate-y-[-50%] left-[-15px] md:left-[-20px] w-[30px] md:w-[40px] h-[30px] md:h-[40px] rounded-full ring-4 ring-yellow ring-offset-4 bg-primary-main flex items-center justify-center cursor-pointer'>
              <FaLongArrowAltLeft className='text-white text-[18px] md:text-[25px]' />
            </div>

            <div className='absolute top-[50%] translate-y-[-50%] right-[-15px] md:right-[-20px] w-[30px] md:w-[40px] h-[30px] md:h-[40px] rounded-full ring-4 ring-yellow ring-offset-4 bg-primary-main flex items-center justify-center cursor-pointer'>
              <FaLongArrowAltRight className='text-white text-[18px] md:text-[25px]' />
            </div>
          </div>
        </div>,
        document.getElementById("lightbox")
      );
}