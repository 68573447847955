import React from "react";
import Button from "../components/buttons";
import Faq from "../components/faq";
import Section from "../components/section";
import Text from "../components/text";
import clx from "../utils/clx";
import { FAQS } from "../constants/faqs";
import Layout from "./layout/layout";
import OurFaqs from "./components/faqs";

export default function SolutionsWrapper({ classname, children }) {
  const classes = clx("", classname);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Layout>
      <Section
        mainClasses="relative min-h-[350px] lg:min-h-[420px] bg-primary-main"
        classname=""
      >
        {/* Background Image */}
        <div className="absolute inset-0 w-full h-full overflow-hidden opacity-20">
          <img
            src="/images/solution-bg2.jpg"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>

        <div className="absolute inset-0 w-[90%] mx-auto py-[60px] lg:py-[80px] flex flex-col justify-center items-center">
          <Text as="h1" classname="text-white text-center">
            Our Solutions
          </Text>
          {/* <Text
            as="article"
            classname="md:w-[70%] lg:w-[40%] mx-auto text-center py-[20px] text-white/80"
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem
            autem cumque voluptatum quia, consequatur velit officiis modi
            provident, fuga culpa ipsa a beatae deleniti sapiente maiores quod
            voluptates temporibus eaque.
          </Text> */}
          <div className="h-[60px] w-[20px] mx-auto">
            <img
              src="/images/arrow-down-gif2.gif"
              alt=""
              className="w-full h-full"
            />
          </div>
        </div>
      </Section>

      <Section classname="flex flex-col lg:flex-row lg:py-[80px] gap-x-6">
        <div className="lg:w-[35%]">
          <Text as="h4" classname="w-fit py-4">
            FXIQ
          </Text>
          <Text as="p" classname="py-3 text-justify text-primary-main/90">
            FXIQ is a client lifecycle management solution offering end-to-end
            automation and comprehensive features to streamline currency
            exchange operations, enabling businesses to increase efficiency,
            reduce errors, and enhance customer experience.
          </Text>
          <a href="https://fxiq.ng" target="_blank" rel="noreferrer" className="flex gap-3 pb-4">
            <Button classname="my-3">
              Check Out FXIQ
            </Button>
          </a>
        </div>
        <div className="flex-1 lg:flex flex-col space-y-6 lg:space-y-0 lg:flex-row">
          <div className="flex-1 flex flex-col gap-y-2 p-2">
            <div className="h-[250px] lg:h-[300px] rounded-3xl overflow-hidden">
              <div className="w-full h-full">
                <img
                  src="/images/image3.jpg"
                  alt=""
                  className="w-full h-full object-fill"
                />
              </div>
            </div>
            <div className="hidden lg:block h-[100px] rounded-lg p-2">
              <div></div>
            </div>
          </div>
          <div className="flex-1 flex flex-col-reverse gap-y-2 p-2">
            <div className="h-[250px] lg:h-[300px] rounded-3xl overflow-hidden">
              <div className="w-full h-full">
                <img
                  src="/images/image6.jpg"
                  alt=""
                  className="w-full h-full object-fill"
                />
              </div>
            </div>
            <div className="hidden lg:block h-[100px] rounded-lg p-2">
              <div></div>
            </div>
          </div>
        </div>

        {/* Mobile Gallery */}
        {/* <div className="lg:hidden flex flex-col gap-y-4">
          <div className="min-h-[250px] rounded-lg overflow-hidden">
            <div className="w-full h-full">
              <img
                src="/images/image6.jpg"
                alt=""
                className="w-full h-full object-fill"
              />
            </div>
          </div>
          <div className="min-h-[120px] border-2 rounded-lg"></div>
        </div> */}
      </Section>

      <Section
        mainClasses="bg-gray-200"
        classname="flex flex-col lg:flex-row-reverse lg:py-[80px] gap-x-6"
      >
        <div className="lg:w-[35%]">
          <Text as="h4" classname="w-fit py-4">
            REGCOMPLY
          </Text>
          <Text as="p" classname="py-3 text-justify text-primary-main/90">
            RegComply is a powerful and innovative solution for businesses
            subject to regulatory compliance. It offers pre-configured
            assessments for regulations such as Money laundering and terrorist
            financing (ML/TF).
          </Text>
          <Text as="p" classname="py-3 text-justify text-primary-main/90">
            All assessments have been expertly crafted and deployed into our
            state-of-the-art platform by experienced policy implementation
            specialists.
          </Text>
          <a href="https://regcomply.io" target="_blank" rel="noreferrer" className="flex gap-3 pb-4">
            <Button classname="my-3">
              Check Out Regcomply
            </Button>
          </a>
        </div>
        <div className="flex-1 flex flex-col space-y-6 lg:space-y-0 lg:flex-row">
          <div className="flex-1 flex flex-col gap-y-2 p-2">
            <div className="h-[250px] lg:h-[300px] rounded-3xl overflow-hidden">
              <div className="w-full h-full">
                <img
                  src="/images/image4.jpg"
                  alt=""
                  className="w-full h-full object-fill"
                />
              </div>
            </div>
            <div className="hidden lg:block h-[100px] rounded-lg p-2">
              <div></div>
            </div>
          </div>
          <div className="flex-1 flex flex-col-reverse gap-y-2 p-2">
            <div className="h-[250px] lg:h-[300px] rounded-3xl overflow-hidden">
              <div className="w-full h-full">
                <img
                  src="/images/image5.jpg"
                  alt=""
                  className="w-full h-full object-fill"
                />
              </div>
            </div>
            <div className="hidden lg:block h-[100px] rounded-lg p-2">
              <div></div>
            </div>
          </div>
        </div>

        {/* Mobile Gallery */}
        {/* <div className="lg:hidden flex flex-col gap-y-4">
          <div className="min-h-[250px] rounded-lg overflow-hidden">
            <div className="w-full h-full">
              <img
                src="/images/image5.jpg"
                alt=""
                className="w-full h-full object-fill"
              />
            </div>
          </div>
          <div className="min-h-[120px] border-2 rounded-lg"></div>
        </div> */}
      </Section>

      <Section classname="lg:py-[80px] gap-x-6">
        <div className="">
          <Text as="h4" classname="w-fit mx-auto py-4">
            Spectra Risk Engine
          </Text>
          <Text
            as="p"
            classname="py-3 text-center md:w-[70%] lg:w-[50%] mx-auto text-primary-main/90"
          >
            Spectra Risk Engine is the ultimate solution for tailored risk
            assessments. With our fully configurable engine, we can create
            custom assessments to align with your unique policies and
            requirements. We recognise that each business has its unique needs,
            and at Spectra, we offer personalised solutions to meet those needs.
          </Text>
          <Text
            as="p"
            classname="py-3 text-center md:w-[70%] lg:w-[50%] mx-auto text-primary-main/90"
          >
            Our approach is tailored to your requirements, from straightforward
            analyses to multi-layered assessments, utilising our expertise and
            cutting-edge technology to achieve the best results. You can
            seamlessly integrate your existing solutions with our platform and
            initiate risk calculations directly from your desk. Alternatively,
            you can use our user-friendly customer-facing platform to run the
            assessments.
          </Text>
          {/* <div className="w-fit mx-auto">
            <Button classname="my-3 mb-4 font-bold" route="/spectra-risk">
              View More
            </Button>
          </div> */}
        </div>
        <div className="flex-1 flex lg:w-[70%] gap-x-2 mx-auto justify-center">
          <div className="border-2 rounded-3xl overflow-hidden">
            <div className="lg:w-[500px]">
              <img
                src="/images/image19.png"
                alt=""
                className="w-full h-full object-center"
              />
            </div>
          </div>
          {/* <div className="flex-1 flex flex-col gap-y-2">
            <div className="h-[240px] border-2 rounded-3xl overflow-hidden">
              <div className="w-full h-full">
                <img
                  src="/images/image19.jpg"
                  alt=""
                  className="w-full h-full object-fill"
                />
              </div>
            </div>
            <div className="h-[100px] border-2 rounded-lg">
              <div></div>
            </div>
          </div>
          <div className="flex-1 flex flex-col-reverse gap-y-2">
            <div className="h-[240px] border-2 rounded-3xl overflow-hidden">
              <div className="w-full h-full">
                <img
                  src="/images/spectra-risk-homepage.png"
                  alt=""
                  className="w-full h-full object-fill"
                />
              </div>
            </div>
            <div className="h-[100px] border-2 rounded-lg">
              <div></div>
            </div>
          </div> */}
        </div>

        {/* Mobile Gallery */}
        {/* <div className="lg:hidden w-[80%] mx-auto flex flex-col gap-y-4">
          <div className="h-[170px] md:h-[300px] border-2 rounded-3xl overflow-hidden">
            <div className="w-full h-full">
              <img
                src="/images/spectra-risk-homepage.png"
                alt=""
                className="w-full h-full object-fill"
              />
            </div>
          </div>
          <div className="min-h-[120px] border-2 rounded-lg"></div>
        </div> */}
      </Section>
      {/* <Section>
        <div className="lg:w-[70%] mx-auto">
          <Text as="h4" classname="w-fit" understroke>
            Frequently Asked Questions
          </Text>
          <div className="py-[30px] flex flex-col gap-y-4">
            <OurFaqs />
            {FAQS.map((question) => (
              <Faq
                key={question.id}
                heading={question.heading}
                question={question.question}
              />
            ))}
          </div>
          <div className="py-[30px] pb-[80px]">
            <Text as="h6" classname="text-primary-main">
              Need to make more enquiries?
            </Text>
            <Button classname="my-3" route="/contact-us">
              Contact Us Now
            </Button>
          </div>
        </div>
      </Section> */}
    </Layout>
  );
}
