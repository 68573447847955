import React from "react";
import Button from "../components/buttons";
import Section from "../components/section";
import Text from "../components/text";
import clx from "../utils/clx";
import Layout from "./layout/layout";

export default function RegcomplyWrapper({ classname, children }) {
  const classes = clx("", classname);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Layout>
      <Section
        mainClasses='relative min-h-[350px] lg:min-h-[420px] bg-primary-main'
        classname=''
      >
        {/* Background Image */}
        <div className='absolute inset-0 w-full h-full overflow-hidden opacity-10'>
          <img
            src='/images/solution-bg2.jpg'
            alt=''
            className='w-full h-full object-cover'
          />
        </div>

        <div className='absolute inset-0 w-[90%] mx-auto py-[60px] lg:py-[80px]'>
          <Text as='h1' classname='text-white text-center'>
            Regcomply
          </Text>
          <Text
            as='article'
            classname='md:w-[70%] lg:w-[40%] mx-auto text-center py-[20px] text-white/80'
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem
            autem cumque voluptatum quia, consequatur velit officiis modi
            provident, fuga culpa ipsa a beatae deleniti sapiente maiores quod
            voluptates temporibus eaque.
          </Text>
          <div className='h-[60px] w-[20px] mx-auto'>
            <img
              src='/images/arrow-down-gif2.gif'
              alt=''
              className='w-full h-full'
            />
          </div>
        </div>
      </Section>
      <Section classname='flex flex-col lg:flex-row gap-y-6 py-[50px] lg:py-[80px] gap-x-6'>
        <div className='lg:w-[35%]'>
          <Text as='h4' classname='w-fit py-4'>
            Regcomply Solutions
          </Text>
          <Text as='p' classname='py-3 text-justify text-primary-main/90'>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestiae
            iste facere dolorem esse? Laborum dolor adipisci facilis eaque
            possimus, corrupti, nam tempora animi harum iusto, quam dignissimos
            sint ad culpa?
          </Text>
          <Text as='p' classname='py-3 text-justify text-primary-main/90'>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestiae
            iste facere dolorem esse? Laborum dolor adipisci facilis eaque
            possimus, corrupti, nam tempora animi harum iusto, quam dignissimos
            sint ad culpa?
          </Text>
        </div>
        <div className='hidden flex-1 md:flex'>
          <div className='flex-1 flex flex-col gap-y-2 p-2'>
            <div className='flex-1 border-2 rounded-lg p-2'></div>
            <div className='h-[100px] border-2 rounded-lg p-2'>
              <div></div>
            </div>
          </div>
          <div className='flex-1 flex flex-col-reverse gap-y-2 p-2'>
            <div className='flex-1 border-2 rounded-lg p-2'></div>
            <div className='h-[100px] border-2 rounded-lg p-2'>
              <div></div>
            </div>
          </div>
        </div>

        {/* Mobile Gallery */}
        <div className='md:hidden flex flex-col gap-y-4'>
          <div className='min-h-[250px] border-2 rounded-lg'></div>
          <div className='min-h-[120px] border-2 rounded-lg'></div>
        </div>
      </Section>
      <Section mainClasses='bg-primary-main' classname='py-[80px]'>
        <Text
          as='h4'
          classname='w-fit mx-auto text-center from-yellow via-white/70'
        >
          What we do at Regcomply
        </Text>
        <Text
          as='p'
          classname='lg:w-[50%] mx-auto py-3 text-center text-white/80'
        >
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestiae
          iste facere dolorem esse? Laborum dolor adipisci facilis eaque
          possimus, corrupti, nam tempora animi harum iusto, quam dignissimos
          sint ad culpa?
        </Text>
        <Text
          as='p'
          classname='lg:w-[50%] mx-auto py-3 text-center text-white/80'
        >
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestiae
          iste facere dolorem esse? Laborum dolor adipisci facilis eaque
          possimus, corrupti, nam tempora animi harum iusto, quam dignissimos
          sint ad culpa?
        </Text>
        <div className='w-fit mx-auto'>
          <Button classname='my-2 bg-white text-primary-main font-bold'>
            Visit Regcomply
          </Button>
        </div>
      </Section>
      <Section classname='flex flex-col gap-y-8 md:flex-row gap-x-[20px] py-[80px]'>
        <div className='flex-1 flex items-center justify-center'>
          <div className='w-[200px] h-[200px]'>
            <img src='/images/mission.png' alt='' />
          </div>
        </div>
        <div className='flex-1'>
          <Text as='h4' classname='w-fit' understroke>
            Regcomply Mission
          </Text>
          <Text as='article' classname='text-primary-main/90 py-6'>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestiae
            iste facere dolorem esse? Laborum dolor adipisci facilis eaque
            possimus, corrupti, nam tempora animi harum iusto, quam dignissimos
            sint ad culpa? Lorem ipsum, dolor sit amet consectetur adipisicing
            elit. Molestiae iste facere dolorem esse? Laborum dolor adipisci
            facilis eaque possimus, corrupti, nam tempora animi harum iusto,
            quam dignissimos sint ad culpa?
          </Text>
        </div>
      </Section>
    </Layout>
  );
}
