import React from "react";
import clx from "../utils/clx";
import Button from "./buttons";
import Text from "./text";

export default function Card({
  classname,
  children,
  content,
  type = "primary",
  src,
  description,
  name,
  label,
  title,
  imgResizer,
  route,
}) {
  const classes = clx(
    "max-w-[300px] border-2 border-primary-main/10 rounded-lg flex flex-col overflow-hidden",
    type === "primary" && "bg-[#F7F7F7] h-[390px] max-h-[390px]",
    type === "secondary" && "bg-primary-main p-4 py-6 min-w-[250px] max-h-fit",
    type === "team" && "min-w-[245px] lg:min-w-[220px] lg:max-w-[280px] min-h-[320px]",
    classname
  );
  switch (type) {
    case "primary":
      return (
        <div className={classes}>
          <div className="flex-1 py-[20px] px-4">
            <div className="h-[60px] max-h-[80px] flex items-center justify-center">
              <div className={imgResizer}>
                <img src={src} alt="" className="w-full h-full" />
              </div>
            </div>
            <Text
              as="p"
              classname="text-center text-[12px] pt-[20px] leading-[1.5] text-primary-main/90"
            >
              {content}
            </Text>
          </div>
          <Button classname="w-full rounded-[0px] py-5" route={route}>
            Check it out
          </Button>
        </div>
      );
    case "secondary":
      return (
        <div className={classes}>
          <div className="h-[150px] max-h-[90px]">
            <div className={imgResizer}>
              <img src={src} alt="" className="w-full h-full" />
            </div>
          </div>
          <div>
            <Text as="h6" classname="text-[20px] text-white">
              {label}
            </Text>
            <Text as="article" classname="text-white/80 py-4 font-normal">
              {description}
            </Text>
          </div>
        </div>
      );
    case "team":
      return (
        <div className={`${classes} `}>
          <div className="w-full h-[250px] lg:h-[300px]">
            <img
              src={src??"/images/profile-pic.png"}
              alt=""
              className="w-full h-full object-fill object-top"
            />
          </div>
          <div className="flex-1 py-4 px-3 text-center flex flex-col items-center justify-center">
            <Text as="h6" classname="text-primary-main">
              {name}
            </Text>
            <Text as="p" classname="text-primary-main/90">
              {title}
            </Text>
            {/* <div className="mt-2">
              <Text as="p" classname="text-primary-main/90">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Temporibus iusto suscipit rem repellat.
              </Text>
            </div> */}
          </div>
        </div>
      );
    default:
      return null;
  }
}
