export const socials = [
  {
    id: 1,
    label: "Twitter",
    href: "https://twitter.com/spectrarisk?s=11&t=kXx1gzJc9sbQTWoyKEHORA",
  },
  {
    id: 2,
    label: "Linkedin",
    href: "https://www.linkedin.com/company/spectra-risk-solutions/",
  },
  {
    id: 3,
    label: "Facebook",
    href: "https://www.facebook.com/spectrarisk?mibextid=LQQJ4d",
  },
];
