import React from "react";
import clx from "../utils/clx";
import Text from "./text";

export default function Label({ classname, icon, children }) {
  const classes = clx("flex items-start gap-x-4", classname);
  return (
    <div className={classes}>
      <div className='w-[25px] h-[25px]'>
        {icon}
        {/* <img src='/images/small-circle.png' alt='' className='w-full h-full' /> */}
      </div>
      <Text as='p' classname='flex-1 text-[13px] font-semibold'>{children}</Text>
    </div>
  );
}
