import React from "react";
import Card from "../components/card";
import LightBox from "../components/lightbox";
import ListItem from "../components/list-item";
import Section from "../components/section";
import Text from "../components/text";
import clx from "../utils/clx";
import OurSolutions from "./components/our-solutions";
import Layout from "./layout/layout";

export default function AboutWrapper({ classname, children }) {
  const [isLightBoxOpen, setLightBoxOpen] = React.useState(false);

  const classes = clx("", classname);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <React.Fragment>
      <Layout classname="">
        <Section
          mainClasses="relative min-h-[350px] lg:min-h-[420px] bg-primary-main"
          classname=""
        >
          <div className="absolute inset-0 w-full h-full overflow-hidden opacity-10">
            <img
              src="/images/about-img.jpg"
              alt=""
              className="w-full h-full object-cover"
            />
          </div>

          {/* <div className='absolute inset-0 flex flex-col lg:flex-row lg:items-center justify-center lg:justify-start w-[90%] mx-auto py-[60px] lg:py-[80px]'>
            <div className='flex-1'>
              <Text
                as='h4'
                classname='text-center lg:text-left from-yellow via-white/70'
              >
                About Spectra Risk Solutions
              </Text>
              <Text
                as='article'
                classname='md:w-[70%] mx-auto lg:mr-auto text-center lg:text-left lg:w-full py-[30px] text-white/80'
              >
                Spectra Risk Solutions has revolutionised time management via
                our automation software & risk engine, enabling us to help drive
                efficiency while unveiling the risks your business may be
                exposed to, leaving room to focus on activities key to driving
                business growth.
              </Text>
              <div className='md:w-[50%] mx-auto lg:w-full grid grid-cols-3 place-items-center lg:place-items-start gap-3'>
                <div className='w-[80px] lg:w-[120px] h-[80px] lg:h-[120px] border-2 rounded-full overflow-hidden'>
                  <img
                    src='/images/solution1.png'
                    alt=''
                    className='w-full h-full object-fill'
                  />
                </div>
                <div className='w-[80px] lg:w-[120px] h-[80px] lg:h-[120px] border-2 rounded-full overflow-hidden'>
                  <img
                    src='/images/about-hero.png'
                    alt=''
                    className='w-full h-full object-fill'
                  />
                </div>
                <div className='w-[80px] lg:w-[120px] h-[80px] lg:h-[120px] border-2 rounded-full overflow-hidden'>
                  <img
                    src='/images/solution1.png'
                    alt=''
                    className='w-full h-full object-fill'
                  />
                </div>
              </div>
            </div>
            <div className='hidden flex-1 lg:flex flex-col items-end relative'>
              <div className='lg:w-[400px] lg:h-[400px] xl:w-[450px] xl:h-[450px]'>
                <img
                  src='/images/about-hero.png'
                  alt=''
                  className='w-full h-full object-contain'
                />
              </div>
            </div>
          </div> */}
          <div className="absolute inset-0 w-[90%] mx-auto py-[60px] lg:py-[80px]">
            <Text as="h1" classname="text-white text-center">
              About Us
            </Text>
            <Text
              as="article"
              classname="md:w-[70%] lg:w-[40%] mx-auto text-center py-[20px] text-white/80"
            >
              Spectra Risk Solutions has revolutionised time management via our
              automation software & risk engine, enabling us to help drive
              efficiency while unveiling the risks your business may be exposed
              to, leaving room to focus on activities key to driving business
              growth.
            </Text>
            <div className="h-[60px] w-[20px] mx-auto">
              <img
                src="/images/arrow-down-gif2.gif"
                alt=""
                className="w-full h-full"
              />
            </div>
          </div>
        </Section>

        <Section classname="py-[50px] lg:py-[80px] flex flex-col md:flex-row items-center gap-y-14 lg:gap-y-0">
          <div className="flex-1">
            <div className="lg:w-[90%]">
              <Text as="h4" classname="w-fit" understroke>
                More About Us
              </Text>
              <Text as="p" classname="text-primary-main/90 py-4">
                At Specta Risk, we are passionate about helping businesses in
                Africa and the 3rd world operate more efficiently and
                effectively. We believe that every organisation should have
                access to powerful tools that enable them to manage their client
                lifecycles with ease, and that's why we develop customised
                client life cycle management (CLM) solutions tailored to the
                unique requirements of these regions.
              </Text>
              <Text as="p" classname="text-primary-main/90 py-4">
                Our approach to developing CLM solutions involves close
                collaboration with industry leaders in Africa and the 3rd world.
                By working closely with these experts, we gain a comprehensive
                understanding of the challenges facing businesses in these
                regions and the specific requirements for managing their client
                lifecycles. This enables us to create customised SaaS solutions
                that streamline business activities and allow these
                organisations to manage their client lifecycles better.
              </Text>
              <Text as="p" classname="text-primary-main/90 py-4">
                As a company, we are committed to providing innovative and
                reliable CLM solutions that help businesses achieve their goals
                and thrive in an ever-changing business environment. So, if you
                are seeking a partner to help create a customised CLM solution
                for your unique use case, look no further than Spectra Risk
                Solutions.
              </Text>
              {/* <Text as="p" classname="text-primary-main/90 py-4">
                We also ensure a clear view of inherent risk posed by customers,
                and their transactions are determined by all the solutions we
                develop.
              </Text> */}
              {/* <Button route="/about-us">More About Us</Button> */}
            </div>
          </div>
          <div className="flex-1 flex justify-end">
            <div className="lg:h-[550px] lg:w-full rounded-[10px] overflow-hidden">
              <img src="/images/image9.jpg" alt="" className="h-full w-full" />
            </div>
          </div>
        </Section>

        

        {/* <Section
          mainClasses="bg-gray-200 relative lg:h-[850px]"
          classname="py-[50px]"
        >
          <OurSolutions
            classname="lg:absolute inset-0 lg:w-[90%] left-[5%] right-[5%] py-[30px] lg:py-[80px]"
          />
        </Section> */}
        {/* <Section classname='md:max-w-[70%] lg:max-w-[50%] pt-[70px] lg:py-[80px]'>
          <Text as='h4' classname='w-fit mx-auto text-primary-main' understroke>
            Why We are the best
          </Text>
          <Text
            as='article'
            classname='py-[30px] text-primary-main/90 text-center'
          >
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae
            explicabo asperiores a quam odit porro provident ducimus sequi
            cupiditate voluptate quasi excepturi mollitia, delectus esse
            corporis nihil officiis eum aperiam. Totam harum numquam illo
            aliquid illum consequuntur optio, enim pariatur facilis nesciunt
            cupiditate delectus, provident atque sapiente ratione. Omnis
            officiis eaque expedita voluptates ad blanditiis impedit modi sed
            quaerat eius?
          </Text>
        </Section> */}
        <Section mainClasses="bg-gray-200" classname="py-[100px] lg:py-[150px] flex flex-col lg:items-center">
          <div className="md:w-[70%] lg:w-[40%] lg:text-center">
            <Text
              as="h4"
              classname="text-primary-main w-fit lg:mx-auto"
              understroke
            >
              Our Team
            </Text>
            <Text classname="py-[20px] text-primary-main/90">
              Our mission is to simplify your firm’s administration, evaluate
              risks and streamline your processes. Our suite of solutions offers
              this.
            </Text>
          </div>
          <div className="flex lg:grid lg:grid-cols-2 gap-6 overflow-y-hidden overflow-x-auto scrollbar scrollbar-thumb-primary-main scrollbar-thumb-rounded-full scrollbar-track-yellow/30 pb-6 max-w-fit lg:mx-auto shadow-lg rounded-3xl p-6 bg-white/50">
            <Card type="team" src="/images/olu-omotoso.png" name="Olu Omotoso" title="CEO/Chief Product Officer"/>
            <Card type="team" src="/images/leke-togun.png" name="Leke Togun" title="Chief Technology Officer"/>
            <Card type="team" src="/images/ronke-soluade.png" name="Ronke Soluade" title="Data Protection Officer"/>
            <Card type="team" src="/images/toni-akinmolayan.png" name="Toni Akinmolayan" title="Head of Engineering"/>
            <Card type="team" src="/images/opeyemi-ajiboye.jpg" name="Opeyemi Ajiboye" title="Head of Marketing"/>
            <Card type="team" src="/images/oloko-opeyemi3.png" name="Sodiq Opeyemi Oloko" title="Software Engineer"/>
          </div>
        </Section>

        {/* <Section classname='py-[80px]'>
          <Text as='h4' classname='text-primary-main w-fit mx-auto text-center'>
            Brands We have Worked with
          </Text>
          <div className='grid grid-cols-3 lg:grid-cols-5 gap-6 lg:gap-0 place-items-center py-[50px]'>
            <div>
              <img src='/images/chase-logo.png' alt='' />
            </div>
            <div>
              <img src='/images/asana-logo.png' alt='' />
            </div>
            <div>
              <img src='/images/google-logo.png' alt='' />
            </div>
            <div>
              <img src='/images/buzzfeed-logo.png' alt='' />
            </div>
            <div>
              <img src='/images/walmart-logo.png' alt='' />
            </div>
          </div>
        </Section> */}
      </Layout>
      <LightBox isOpen={isLightBoxOpen} setOpen={setLightBoxOpen} />
    </React.Fragment>
  );
}
